import Vue from "vue";
import vuetify from "@/vuetify/vuetify";
import Landing from "@/Landing.vue";
//
import store from './store'


import '@/util/bus'
import '@/util/components'
import '@/filters'

new Vue({
  el: '#app',
  vuetify,
  store,
  // render: h => h(App)
  ...Landing,
})
