<template>
  <div>
    <div v-if="!is_finished" class="panel-heading border-bottom py-2 mb-5">
      <h3 class="text-center">Формуляр за записване</h3>
    </div>

    <v-stepper
      v-if="!is_finished"
      v-model="step" non-linear vertical max-width="650"
      @change="saveData"
    >
      <v-stepper-step
        :color="$v.child.$error ? 'red':'primary'"
        edit-icon="fa-check text-sm"
        :complete="!$v.child.$error"
        :editable="!loading"
        step="1"
        :rules="[() => !$v.child.$error]"
      >Ученик {{ $v.child.$error ? '(моля, попълнете)' : '' }}
        <v-btn icon small class="ms-auto" v-if="step > 1">
          <v-icon small>fa-edit</v-icon>
        </v-btn>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-card class="m-auto" color="accent">
          <v-card-title>Данни за детето</v-card-title>
          <v-card-text>
            <div class="form-group">
              <label class="col-form-label" for="child-name">Име</label>
              <input
                class="form-control"
                v-model="child.name"
                type="text"
                id="child-name"
                :class="{ 'is-invalid': $v.child.name.$error }"
              >
            </div>
            <v-row>
              <v-col>
                <div class="form-group">
                  <label class="col-form-label" for="child-birthdate">Дата на раждане</label>
                  <date-picker
                    id="child-birthdate"
                    v-model="child.birthdate"
                    :class="{ 'is-invalid': $v.child.birthdate.$error }"
                  ></date-picker>
                  <div class="error-text invalid-feedback" v-if="$v.child.birthdate.$error">
                    въведете рожденна дата
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="form-group">
                  <label class="col-form-label" for="child-phone">Телефон</label>
                  <input class="form-control" v-model="child.phone" type="text" id="child-phone">
                </div>
              </v-col>
            </v-row>
            <div class="form-group">
              <label class="col-form-label" for="child-school">Училище</label>
              <input class="form-control" v-model="child.school" type="text" id="child-school">
            </div>
            <div class="row">
              <div class="col-3">
                <label>Клас</label>
                <select class="custom-select" v-model="child.class_num">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
              <div class="col-3">
                <label>Паралелка</label>
                <select class="custom-select" v-model="child.class_char">
                  <option>А</option>
                  <option>Б</option>
                  <option>В</option>
                  <option>Г</option>
                  <option>Д</option>
                  <option>Е</option>
                </select>
              </div>
              <div class="col-6">
                <label>Класен ръководител</label>
                <input
                  class="form-control"
                  v-model="child.teacher_name"
                  :class="{ 'is-invalid': $v.child.teacher_name.$error }"
                >
              </div>
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="stepChild" color="primary">
              Продължи
              <v-icon class="ms-3">fas fa-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        :color="!parentsValid ? 'red':'primary'"
        edit-icon="fa-check text-sm"
        :editable="!loading"
        :complete="parentsValid"
        :rules="[() => this.parentsValid]"
      >
        Родители {{ parentsValid ? '' : '(попълнете данните на поне един родител)' }}
        <v-btn icon small class="ms-auto" v-if="step > 2">
          <v-icon small>fa-edit</v-icon>
        </v-btn>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-card class="mx-auto mt-3" color="accent">
          <v-card-title>Данни за родителите</v-card-title>
          <v-card-text>
            <h5>Майка</h5>
            <register-contact-item v-model="mother"></register-contact-item>
            <v-divider></v-divider>
            <h5>Баща</h5>
            <register-contact-item v-model="father"></register-contact-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="stepParents" color="primary">
              Продължи
              <v-icon class="ms-3">fas fa-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        step="3"
        :editable="!loading"
        :color="$v.doctor.$error ? 'red':'primary'"
        edit-icon="fa-check text-sm"
        :complete="!$v.doctor.$error"
        :rules="[() => !$v.doctor.$error]"
      >Личен лекар {{ $v.doctor.$error ? '(моля попълнете)' : '' }}
        <v-btn icon small class="ms-auto" v-if="step > 3">
          <v-icon small>fa-edit</v-icon>
        </v-btn>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-card class="mx-auto mt-3" color="accent">
          <v-card-title>Данни за личния лекар</v-card-title>
          <v-card-text>
            <register-contact-item v-model="doctor" :v="$v.doctor"></register-contact-item>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="stepDoctor" color="primary">
              Продължи
              <v-icon class="ms-3">fas fa-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        step="4"
        :editable="!loading"
        :color="!questionsValid ? 'red':'primary'"
        edit-icon="fa-check text-sm"
        :complete="questionsValid"
        :rules="[() => questionsValid]"
      >Анкетна карта {{ !questionsValid ? '(моля отговорете)' : '' }}
      </v-stepper-step>
      <v-stepper-content step="4">
        <register-questions title="Здравословни проблеми" v-model="questions"
                            :v="$v.questions"></register-questions>
        <register-questions title="Навици и интереси" v-model="interests"
                            :v="$v.interests"></register-questions>
        <register-questions title="Други" v-model="others" :v="$v.others"></register-questions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="stepQestions" color="primary">
            Продължи
            <v-icon class="ms-3">fas fa-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step editable step="5">Край</v-stepper-step>
      <v-stepper-content step="5">
        <v-sheet
          color="accent"
          class="p-5"
          elevation="3"
        >
          <div>
            <p>Декларирам, че предоставената информация е вярна и чрез подписа си се съгласявам синът ми /
              дъщеря ми да посещава Детски образователен център „Пъстър свят”.</p>

            <h5 class="py-4">Родителят/настойникът на обучаемия се задължава:</h5>

            <p>Да внася таксата за обучение в определения за това срок. При отсъствие поради болест или
              невъзможност за посещение на заниманията - да уведоми своевременно преподавателя до 09,00
              часа
              на
              текущия ден.</p>
            <p>Да се въздържа от неправомерни постъпки и коментари, уронващи авторитета на детския център,
              служителите и останалите обучаеми.
            </p>
          </div>

          <v-row class="mt-5 pt-5">
            <v-col>
              <div>Дата: {{ date|date }}</div>
              <div>гр. София</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <div>Родител:</div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-textarea solo flat label="Забележка" v-model="child.note"></v-textarea>
          <v-row>

            <v-col class="text-center">
              <v-btn x-large color="primary" @click="submitData">
                Подайте формуляра
                <v-icon class="ml-3">fa fa-book</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-overlay absolute :value="loading">
          <div class="flex flex-column">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            >

            </v-progress-circular>
            <div class="p-5">Моля изчакайте ...</div>
          </div>
        </v-overlay>
      </v-stepper-content>
    </v-stepper>

    <v-card
      v-if="is_finished"
      color="accent"
      class="p-5 text-center"
    >
      <v-card-title class="text-h5">
        Вашата заявка беше приета
      </v-card-title>

      <v-icon x-large color="success">fa fa-check-circle</v-icon>

      <v-card-subtitle class="py-5">Очаквайте обаждане от нас.</v-card-subtitle>

      <v-card-actions v-if="true">
        <v-spacer></v-spacer>
        <v-btn text link href="https://abccenter.bg/">
          Към сайта на ДОЦ "Пъстър свят"
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-row class="justify-center mb-5">
      <v-btn @click="resetForm" color="error lighten-1"><v-icon class="mr-2" small>fa-exclamation-triangle</v-icon> Започни отначало</v-btn>
    </v-row>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import RegisterContactItem from "@/landing/RegisterContactItem.vue";
import RegisterQuestions from "@/landing/RegisterQuestions.vue";
import {required} from "vuelidate/lib/validators";
import api from "@/util/api";

export default {
  name: "Register",
  components: {RegisterQuestions, RegisterContactItem, DatePicker},
  mounted () {
    // this.loadData()
  },
  validations: {
    questions: {
      $each: {
        answer: {required}
      }
    },
    interests: {
      $each: {
        answer: {required}
      }
    },
    others: {
      $each: {
        answer: {required}
      }
    },
    child: {
      name: {required},
      birthdate: {required},
      teacher_name: {required},
      // school: {required}
    },
    mother: {
      name: {required},
      address: {required},
      phone: {required},
      email: {required},
    },
    father: {
      name: {required},
      address: {required},
      phone: {required},
      email: {required},
    },
    doctor: {
      name: {required},
      phone: {required},
    },
  },
  data () {
    const birth = new Date()
    birth.setFullYear(birth.getFullYear() - 10)
    return {
      is_finished: false,
      loading: false,
      date: Date.now() / 1000,
      step: 1,
      others: [
        {question: 'От къде научихте за нас?', answer: ''},
      ],
      interests: [
        {question: 'Към какво изпитва интерес Вашето дете?', answer: ''},
        {question: 'Има ли храни, които не разрешавате на Вашето дете?', answer: '[Не]', options: ['Не', 'Да']},
        {question: 'Изпитва ли страх от нещо?', answer: '[Не]', options: ['Не', 'Да']},
      ],
      questions: [
        {question: 'Алергично ли е детето и към какво?', answer: '[Не]', options: ['Не', 'Да']},
        {
          question: 'Има ли детето проблеми със зрението, слуха, говора? Ако отговорът е да, какви?',
          answer: '[Не]',
          options: ['Не', 'Да']
        },
        {question: 'Често ли боледува? От какво?', answer: '[Не]', options: ['Не', 'Да']},
        {question: 'Трябва ли да спазва специална диета?', answer: '[Не]', options: ['Не', 'Да']},
      ],
      child: {
        name: '',
        phone: '',
        birthdate: null,
        class_num: 1,
        class_char: 'А',
        teacher_name: '',
        note: '',
        school: '105 СОУ „Атанас Далчев“'
      },
      mother: {
        name: '',
        address: '',
        phone: '',
        email: ''
      },
      father: {
        name: '',
        address: '',
        phone: '',
        email: ''
      },
      doctor: {
        name: '',
        address: '',
        phone: '',
        email: ''
      },
    }
  },
  methods: {
    stepChild () {
      this.saveData()

      this.$v.child.$touch()
      if (!this.$v.child.$error) {
        this.step++
      }
    },
    stepParents () {
      this.saveData()

      this.$v.mother.$touch()
      this.$v.father.$touch()
      if (this.parentsValid) {
        this.step++
      }
    },
    stepDoctor () {
      this.saveData()

      this.$v.doctor.$touch()
      if (!this.$v.doctor.$error) {
        this.step++
      }
    },
    stepQestions () {
      this.saveData()
      this.$v.questions.$touch()
      this.$v.interests.$touch()
      this.$v.others.$touch()
      if (this.questionsValid) {
        this.step++
      }
    },
    loadData () {
      if (window.localStorage.child) {
        this.child = JSON.parse(window.localStorage.child)
      }
      if (window.localStorage.mother) {
        this.mother = JSON.parse(window.localStorage.mother)
      }
      if (window.localStorage.father) {
        this.father = JSON.parse(window.localStorage.father)
      }
      if (window.localStorage.doctor) {
        this.doctor = JSON.parse(window.localStorage.doctor)
      }
      //
      if (window.localStorage.questions) {
        this.questions = JSON.parse(window.localStorage.questions)
      }
      if (window.localStorage.interests) {
        this.interests = JSON.parse(window.localStorage.interests)
      }
      if (window.localStorage.others) {
        this.others = JSON.parse(window.localStorage.others)
      }
      this.step = window.localStorage?.step || 1
    },
    resetForm () {
      this.clearData()
      this.loadData()
    },
    saveData () {
      window.localStorage.child = JSON.stringify(this.child)
      window.localStorage.mother = JSON.stringify(this.mother)
      window.localStorage.father = JSON.stringify(this.father)
      window.localStorage.doctor = JSON.stringify(this.doctor)

      window.localStorage.questions = JSON.stringify(this.questions)
      window.localStorage.interests = JSON.stringify(this.interests)
      window.localStorage.others = JSON.stringify(this.others)

      window.localStorage.step = this.step
    },
    clearData () {
      window.localStorage.removeItem('child')
      window.localStorage.removeItem('mother')
      window.localStorage.removeItem('father')
      window.localStorage.removeItem('doctor')

      window.localStorage.removeItem('questions')
      window.localStorage.removeItem('interests')
      window.localStorage.removeItem('others')

      window.localStorage.removeItem('step')
    },
    submitData () {
      this.$v.$touch()
      this.saveData()

      const is_valid = !this.$v.child.$error && this.parentsValid && !this.$v.doctor.$error && this.questionsValid
      if (is_valid) {
        //persist on local storage
        this.loading = true

        api.post('/api/crm/register', {
          child: this.child,
          mother: this.mother,
          father: this.father,
          doctor: this.doctor,
          questions: this.questions.map(x => ({...x, question_group: 'Здравословни проблеми'}))
            .concat(this.interests.map(x => ({...x, question_group: 'Навици и интереси'})))
            .concat(this.others.map(x => ({...x, question_group: 'Други'})))
        }).then(() => {
          this.is_finished = true;
          this.clearData()
        })
      }
    },
  },
  computed: {
    parentsValid () {
      return !this.$v.mother.$error || !this.$v.father.$error
    },
    questionsValid () {
      return !this.$v.questions.$error && !this.$v.interests.$error && !this.$v.others.$error
    }
  }
}
</script>
