<template>
    <div class="container">
        {{ $options.uuid }}
        <div class="form-group">
            <label class="col-form-label" for="child-name">Име</label>
            <input
              class="form-control"
              v-model="value.name"
              :class="{ 'is-invalid': v && v.name && v.name.$error }"
              type="text" id="child-name">
        </div>
        <div class="form-group">
            <label class="col-form-label" for="child-name">Адрес</label>
            <input
              class="form-control"
              :class="{ 'is-invalid': v && v.address && v.address.$error }"
              v-model="value.address" type="text" id="child-name">
        </div>
        <div class="form-group">
            <label class="col-form-label" for="child-name">Телефон</label>
            <input
              class="form-control"
              :class="{ 'is-invalid': v && v.phone && v.phone.$error }"
              v-model="value.phone" type="text" id="child-name">
        </div>
        <div class="form-group">
            <label class="col-form-label" for="child-name">Email</label>
            <input
              class="form-control"
              :class="{ 'is-invalid': v && v.email && v.email.$error }"
              v-model="value.email" type="text" id="child-name">
        </div>
    </div>
</template>

<script>
export default {
  name: "RegisterContactItem",
  props: {
    value: Object,
    v: Object
  }
}
</script>

<style scoped>

</style>
