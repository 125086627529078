<template>
    <div>
        <v-card-title>{{ title }}</v-card-title>
        <v-divider></v-divider>
        <dl>
            <template v-for="(item, idx) in value">
                <dt :key="'dt' + idx">{{ idx + 1 }}. {{ item.question }}</dt>
                <dd :key="'dd' + idx">
                    <v-radio-group
                            row
                            v-if="item.options"
                            :value="getOption(item)"
                            @change="changeOption($event, item)"
                    >
                        <v-radio
                                v-for="option in item.options"
                                :key="option"
                                :label="option"
                                :value="option"
                        ></v-radio>
                    </v-radio-group>
                    <v-textarea
                            v-if="getOption(item)==='Да'"
                            hide-details
                            :class="{'is-invalid': v && v.$each[idx].answer.$error}"
                            rows="2" flat outlined v-model="item.answer"
                    ></v-textarea>
                    <div class="error-text invalid-feedback" v-if="v && v.$each[idx].answer.$error">
                        моля отговорете на въпроса
                    </div>
                </dd>
            </template>
        </dl>

    </div>
</template>

<script>
export default {
  name: "RegisterQuestions",
  props: {
    title: String,
    value: Array,
    v: Object,
    options: []
  },
  methods: {
    getOption (item) {
      if (Array.isArray(item.options)) {
        return item.answer.toLowerCase() !== '[не]' ? 'Да' : 'Не'
      }
      return 'Да'
    },
    changeOption (event, item) {
      if (event !== 'Да')
        item.answer = '[' + event + ']'
      else
        item.answer = ''
    }
  }
}
</script>
