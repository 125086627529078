<template>
    <register></register>
</template>

<script>
import Register from "@/landing/Register.vue";

export default {
  name: "Landing",
  components: {Register}
}
</script>

<style lang="scss">
@import "assets/bootstrap";
@import "assets/landing";
</style>
